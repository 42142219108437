export const products = [
  {
    id: 1,
    img: "https://storage.googleapis.com/images.luqmanzakariya.com/static/indopedia-merchant.png",
    link: "https://www.indopedia.id/",
  },
  {
    id: 2,
    img: "https://storage.googleapis.com/images.luqmanzakariya.com/static/indopedia-seller.png",
    link: "https://www.indopedia.id/login/seller",
  },
  {
    id: 3,
    img: "https://storage.googleapis.com/images.luqmanzakariya.com/static/indopedia-admin.png",
    link: "https://admin.indopedia-tech.web.id/",
  },
  {
    id: 4,
    img: "https://storage.googleapis.com/images.luqmanzakariya.com/static/wedding.png",
    link: "https://olapuspita.luqmanzakariya.com/",
  },
  {
    id: 5,
    img: "https://storage.googleapis.com/images.luqmanzakariya.com/static/centrace.png",
    link: "https://centrace.biz.myboost.co.id/login",
  },
  {
    id: 6,
    img: "https://storage.googleapis.com/images.luqmanzakariya.com/static/boost-kedai-seller-3.png",
    link: "https://seller.biz.myboost.co.id",
  },
  {
    id: 7,
    img: "https://storage.googleapis.com/images.luqmanzakariya.com/static/bareksa_com_reksadana_page.png",
    link: "https://www.bareksa.com/reksadana",
  },
  {
    id: 8,
    img: "https://storage.googleapis.com/images.luqmanzakariya.com/static/payment_bareksa_com.png",
    link: "https://www.bareksa.com/",
  },
  {
    id: 9,
    img: "https://storage.googleapis.com/images.luqmanzakariya.com/static/danastraportal.png",
    link: "https://danastraportal.com",
  },
  {
    id: 10,
    img: "https://storage.googleapis.com/images.luqmanzakariya.com/static/pohon2_bareksa_com.png",
    link: "https://pohon2.bareksa.com",
  },
  {
    id: 11,
    img: "https://storage.googleapis.com/images.luqmanzakariya.com/static/prioritas_bareksa_com.png",
    link: "https://prioritas.bareksa.com/",
  },
  {
    id: 12,
    img: "https://storage.googleapis.com/images.luqmanzakariya.com/static/prioritasinvestor_bareksa_com.png",
    link: "https://prioritasinvestor.bareksa.com/",
  },
];
