import React from "react";
import "./about.css";
// import Award from "../../img/award.png";

const About = () => {
  return (
    <div className="a">
      <div className="a-left">
        <div className="a-card bg"></div>
        <div className="a-card">
          <img
            src="https://storage.googleapis.com/images.luqmanzakariya.com/static/about_me.jpg"
            alt=""
            className="a-img"
          />
        </div>
      </div>
      <div className="a-right">
        <h1 className="a-title">About Me</h1>
        <p className="a-sub">
          Experienced and self-motivated Software Engineer, eager to continually
          expand knowledge and skill set. Possessing a robust foundation in
          business and international exposure from living as an expatriate. This
          includes collaborating with cross-functional teams across the US,
          Latin America, and Asia, tackling intricate challenges. Fluent in both
          English and Indonesian languages.
        </p>
        <p className="a-sub">
          Graduated from Hacktiv8 institution, I am a self-motivated and
          eager-to-learn Software Engineer well-versed in the intricacies of
          both frontend and backend development for both web and mobile
          applications.
        </p>
        <p className="a-desc">
          I'm always available for freelancing if the right project comes along.
          Feel free to reach me out at{" "}
          <a href="mailto:luqman.zakariya@gmail.com">
            luqman.zakariya@gmail.com
          </a>
        </p>
        {/* <div className="a-award">
          <img src={Award} alt="" className="a-award-img" />
          <div className="a-award-texts">
            <h4 className="a-award-title">International Design Awards 2021</h4>
            <p className="a-award-desc">
              Nemo enim ipsam voluptatem quia voluptas sit aspernatur autodit
              and fugit.
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default About;
