import React from "react";
import IconLinkedin144 from "../../img/sosmedResume/icons8-linkedin-144.png";
import IconResume64 from "../../img/sosmedResume/icons8-resume-64.png";
import IconGithub144 from "../../img/sosmedResume/icons8-github-144.png";
import FileResume from "../../file/CV_18_Aug 23.pdf";
import "./sosmedAndResume.css";

const SosmedAndResume = () => {
  return (
    <div className="pl">
      <div className="pl-texts">
        <h1 className="pl-title">Social Media</h1>
        <div className="sosmed-wrapper">
          <a
            href={"https://www.linkedin.com/in/luqmanzakariya/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={IconLinkedin144} alt="icn" />
          </a>
          <a
            href={"https://github.com/luqmanzakariya"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={IconGithub144} alt="icn" />
          </a>
          <a
            href={FileResume}
            download="Resume 18 Aug 23"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={IconResume64} alt="icn" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SosmedAndResume;
